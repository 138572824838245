<template>
	<div class="page-content no-padding">
        <div class="grid">
            <div class="col-12">
                <Toolbar class="p-mb-4" v-if="userCanCreate">
                    <template #start>
                        <Button label="Crear" icon="pi pi-plus" class="p-button-success mr-2" @click="showCreateCategoryDialog" />
                    </template>
                    <template #end>
                    </template>
                </Toolbar>
                <TreeTable :value="categories" class="p-treetable-gridlines" :rowHover="true" :loading="loading" responsiveLayout="scroll" :rowClass="rowClass" sortField="created_at" :sortOrder="-1" removableSort>
                    <template #header>
                        <div class="table-header flex flex-column md:flex-row md:justify-content-between">
                            <h5 class="mb-2 md:m-0 md:align-self-center">{{ $appState.texts.categories }}</h5>
                            <div class="flex flex-column md:flex-row">
                                <ToggleButton v-model="withTrashed" onLabel="Sin eliminadas" offLabel="Ver eliminadas" onIcon="pi pi-eye-slash" offIcon="pi pi-eye" @change="fetchCategories" class="mb-2 mr-0 md:mb-0 md:mr-2" v-if="userCanRestore" />
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="searchQuery" placeholder="Buscar..." />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        No hay {{ $appState.texts.categories }}.
                    </template>
                    <template #loading>
                        Cargando datos, espera por favor...
                    </template>
                    <Column header="Nombre" field="name" style="min-width:12rem" :sortable="true" :expander="true">
                        <template #body="props">
                            <div style="display: inline;">
                                <iconify-icon v-if="props.node.data.extra_data.icon_type === 'icon'" :icon="props.node.data.extra_data.icon ?? 'mdi:folder-text'" class="mr-2" :style="`color: #${props.node.data.extra_data.icon_color ?? '6366F1'};font-size: 1.6em;`"></iconify-icon>
                                <img v-if="props.node.data.extra_data.icon_type === 'image'" :src="getIconImageUrl(props.node.data.id)" class="mr-2" style="height: 1.6em;" alt="">
                                <span>
                                    {{props.node.data.name}}
                                    ({{props.node.data.slug}})
                                </span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Descripción" field="short_desc" style="min-width:12rem">
                        <template #body="props">
                            {{props.node.data.short_desc}}
                        </template>
                    </Column>
                    <Column header="Creada el" field="created_at" dataType="created_at" style="min-width:10rem" :sortable="true">
                        <template #body="props">
                            {{formatDate(props.node.data.created_at)}}
                        </template>
                    </Column>
                    <Column :exportable="false" style="min-width:8rem">
                        <template #body="props">
                            <template v-if="!props.node.data.deleted_at">
                                <Button v-if="userCanUpdate" icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="showUpdateCategoryDialog(props.node.data.id)" />
                                <Button v-if="userCanDelete && !props.node.data.hasDocuments && !props.node.data.extra_data.is_root" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteCategory(props.node.data)" />
                                <Button v-if="userCanDelete && (props.node.data.hasDocuments || props.node.data.extra_data.is_root)" icon="pi pi-trash" class="p-button-rounded p-button-danger" disabled v-tooltip.left="{label: 'No se puede eliminar, contiene documentos'}" />
                            </template>
                            <template v-else-if="userCanRestore">
                                <Button icon="pi pi-undo" class="p-button-rounded p-button-success" @click="confirmRestoreCategory(props.node.data)" />
                            </template>
                        </template>
                    </Column>
                </TreeTable>
            </div>
        </div>
    </div>
    <Dialog v-model:visible="deleteCategoryDialog" :style="{width: '450px'}" header="Confirmar eliminación" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="category">¿Quieres eliminar la categoría <b>{{category.name}}</b>?</span>
            <span v-if="category.subcategories && category.subcategories.length > 0"><strong>ADVERTENCIA: Esta {{ $appState.texts.category }} contiene {{ $appState.texts.subcategories }}. Al eliminar la {{ $appState.texts.category }} éstas pasarán a no tener {{ $appState.texts.category }} padre.</strong></span>
            <div class="flex flex-row items-center mb-4 mt-4">
                <Checkbox v-model="deleteSubcategories" :binary="true" id="deleteSubcategories" class="mr-2" />
                <label for="deleteSubcategories">Eliminar también las {{ $appState.texts.subcategories }}</label>
            </div>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteCategoryDialog = false"/>
            <Button label="Sí" icon="pi pi-trash" class="p-button-text p-button-danger" @click="deleteCategory" />
        </template>
    </Dialog>

    <Dialog v-model:visible="restoreCategoryDialog" :style="{width: '450px'}" header="Confirmar restauración" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="category">¿Quieres restaurar la {{ $appState.texts.category }} <b>{{category.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="restoreCategoryDialog = false"/>
            <Button label="Sí" icon="pi pi-undo" class="p-button-text p-button-success" @click="restoreCategory" />
        </template>
    </Dialog>

    <Dialog v-model:visible="createCategoryDialog" :style="{width: '450px'}" header="Nueva" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <div class="field mb-4">
                <label for="name">Nombre</label>
                <InputText v-model="v$.newCategory.name.$model" id="name" type="text" class="w-full mb-2" :class="{'p-invalid': v$.newCategory.name.$error}" required @keyup="newCategoryNameChange" />
                <div v-for="error of v$.newCategory.name.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="parent">{{ $appState.texts.category }} padre</label>
                <TreeSelect v-model="v$.newCategory.parent_category.$model" :options="allCategories" class="w-full mb-2" required @change="parentChange(false)" />
                <div v-for="error of v$.newCategory.parent_category.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="short_desc">Descripción</label>
                <InputText v-model="newCategory.short_desc" id="short_desc" type="short_desc" class="w-full mb-2" />
            </div>
            <div class="field mb-4">
                <label for="slug">Meta URL (lo que figura en la URL al ver la {{ $appState.texts.category }})</label>
                <InputText v-model.trim="v$.newCategory.slug.$model" id="slug" type="slug" class="w-full mb-2" :class="{'p-invalid': v$.newCategory.slug.$error}" required />
                <div v-for="error of v$.newCategory.slug.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="listType">Tipo de listado</label>
                <Dropdown v-model="newCategory.listType" :options="listTypes" optionLabel="name" dataKey="value" class="w-full mb-2" required />
            </div>
            <div class="field mb-4">
                <label for="iconType">Tipo de icono</label>
                <Dropdown v-model="newCategory.iconType" :options="iconTypes" optionLabel="name" dataKey="value" class="w-full mb-2" required />
            </div>
            <div class="field mb-4" v-if="newCategory.iconType.value === 'icon'">
                <div class="flex flex-row align-items-center">
                    <iconify-icon :icon="v$.newCategory.icon.$model" class="mr-4" :style="`color: #${newCategory.iconColor}; font-size: 1.8em;`" ></iconify-icon>
                    <Button label="Cambiar icono" icon="pi pi-search" class="p-button-primary w-auto" @click="selectIcon(false)" :disabled="working" />
                    <ColorPicker v-model="newCategory.iconColor" class="ml-3" style="width: 42px;" />
                </div>
                <div v-for="error of v$.newCategory.icon.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <FileUpload v-if="newCategory.iconType.value === 'image'" name="iconImage" :customUpload="true" @uploader="fileChanged" @select="fileChanged" mode="advanced" :showUploadButton="false" chooseLabel="Seleccionar imagen" accept="image/*" :fileLimit="1" />

            <div class="mt-4" v-if="registerError"><small class="p-error font-bold">{{ registerError }}</small></div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="createCategoryDialog = false" :disabled="working" />
            <Button label="Guardar" icon="pi pi-save" :loading="working" class="p-button-text p-button-success" @click="createCategory(false)" :disabled="canCreateCategory || working" />
            <Button label="Guardar y crear otra" icon="pi pi-save" :loading="working" class="p-button-text p-button-success" @click="createCategory(true)" :disabled="canCreateCategory || working" />
        </template>
    </Dialog>

    <Dialog v-model:visible="updateCategoryDialog" :style="{width: '450px'}" header="Editar" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <div class="field mb-4">
                <label for="title">Nombre</label>
                <InputText v-model="v$.updateCategory.name.$model" id="name" type="text" class="w-full mb-2" :class="{'p-invalid': v$.updateCategory.name.$error}" required @keyup="updateCategoryNameChange" />
                <div v-for="error of v$.updateCategory.name.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="parent">{{ $appState.texts.category }} padre</label>
                <TreeSelect v-model="v$.updateCategory.parent_category.$model" :options="allCategories" class="w-full mb-2" required @change="parentChange(true)" />
                <div v-for="error of v$.updateCategory.parent_category.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="description">Descripción</label>
                <InputText v-model="updateCategory.short_desc" id="short_desc" type="short_desc" class="w-full mb-2" />
            </div>
            <div class="field mb-4">
                <label for="slug">Meta URL (lo que figura en la URL al ver la {{ $appState.texts.category }})</label>
                <InputText v-model.trim="v$.updateCategory.slug.$model" id="slug" type="slug" class="w-full mb-2" :class="{'p-invalid': v$.updateCategory.slug.$error}" required />
                <div v-for="error of v$.updateCategory.slug.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="listType">Tipo de listado</label>
                <Dropdown v-model="updateCategory.listType" :options="listTypes" optionLabel="name" dataKey="value" class="w-full mb-2" required />
            </div>
            <div class="field mb-4">
                <label for="iconType">Tipo de icono</label>
                <Dropdown v-model="updateCategory.iconType" :options="iconTypes" optionLabel="name" dataKey="value" class="w-full mb-2" required @change="setDefaultIcon" />
            </div>
            <div class="field mb-4" v-if="updateCategory.iconType.value === 'icon'">
                <div class="flex flex-row align-items-center">
                    <iconify-icon :icon="v$.updateCategory.icon.$model" class="mr-4" style="font-size: 1.8em;"></iconify-icon>
                    <Button label="Cambiar icono" icon="pi pi-search" class="p-button-primary w-auto" @click="selectIcon(true)" :disabled="working" />
                    <ColorPicker v-model="updateCategory.iconColor" class="ml-3" style="width: 42px;" />
                </div>
                <div v-for="error of v$.updateCategory.icon.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <FileUpload v-if="updateCategory.iconType.value === 'image'" name="iconImage" :customUpload="true" @uploader="fileChanged" @select="fileChanged" mode="advanced" :showUploadButton="false" chooseLabel="Seleccionar imagen" accept="image/*" :fileLimit="1" />

            <div class="mt-4" v-if="updateError"><small class="p-error font-bold">{{ updateError }}</small></div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="updateCategoryDialog = false" :disabled="working" />
            <Button label="Guardar" icon="pi pi-save" :loading="working" class="p-button-text p-button-success" @click="saveCategory" :disabled="canUpdateCategory || working" />
        </template>
    </Dialog>
</template>

<script>
import RouteGuardService from '@/service/RouteGuardService.js';
import ApiService from '@/service/ApiService.js';
import AuthService from '@/service/AuthService.js';
import CategoryService from '@/service/CategoryService.js';
import ErrorReportService from '@/service/ErrorReportService.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import CustomValidators from '@/customValidators';
import ValidationMessages from '@/validationMessages';

const compUrl = '/categories';
const listTypes = [
    {
        name: 'Lista',
        value: 'list'
    },
    {
        name: 'Galería',
        value: 'gallery'
    }
];
const iconTypes = [
    {
        name: 'Icono',
        value: 'icon'
    },
    {
        name: 'Imagen',
        value: 'image'
    }
];

export default {
    beforeRouteEnter() {
        let goTo = RouteGuardService.isLoggedIn();

        if (goTo !== true) {
            window.sessionStorage.setItem('afterLogin', compUrl);
        }

        return goTo;
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            categories: null,
            allCategories: null,
            categoryList: null,
            allSlugs: [],
            withTrashed: false,
            loading: true,
            deleteCategoryDialog: false,
            restoreCategoryDialog: false,
            createCategoryDialog: false,
            updateCategoryDialog: false,
            category: null,
            query: '',
            qTimeout: null,
            newCategory: {
                parent_category: null,
                name: '',
                short_desc: '',
                slug: '',
                iconType: iconTypes[0],
                icon: 'mdi:folder-text',
                iconColor: '6366F1',
                listType: listTypes[0]
            },
            validationMessages: {},
            working: false,
            registerError: null,
            updateCategory: {
                parent_category: null,
                name: '',
                short_desc: '',
                slug: '',
                iconType: iconTypes[0],
                icon: 'mdi:folder-text',
                iconColor: '6366F1',
                listType: listTypes[0]
            },
            updateError: null,
            deleteSubcategories: false,
            listTypes: listTypes,
            iconTypes: iconTypes
        }
    },
    authService: null,
    categoryService: null,
    errorReportService: null,
    selectedFile: null,
    created() {
        this.authService = new AuthService();
        this.categoryService = new CategoryService();
        this.errorReportService = new ErrorReportService();
        this.validationMessages = ValidationMessages;
    },
    validations() {
        return {
            newCategory: {
                name: { required },
                slug: { required, slug: CustomValidators.slug, uniqueSlug: CustomValidators.uniqueSlug },
                icon: { required },
                parent_category: { required },
                listType: { required },
            },
            updateCategory: {
                name: { required },
                slug: { required, slug: CustomValidators.slug, uniqueSlug: CustomValidators.uniqueSlug },
                icon: { required },
                parent_category: { required },
                listType: { required },
            }
        };
    },
    emits: ['updateBreadcrumbs', 'selectIcon'],
    mounted() {
        this.$emit('updateBreadcrumbs', {label: this.$appState.texts.categories, to: compUrl, replace: true});

        if (!this.$appState.estate) {
            this.$watch(
                () => this.$appState.estate,
                () => {
                    this.fetchCategories();
                },
            );
        } else {
            this.fetchCategories();
        }
    },
    computed: {
        searchQuery: {
            get() {
                return this.query;
            },
            set(value) {
                if (this.qTimeout) {
                    clearTimeout(this.qTimeout);
                }
                this.qTimeout = setTimeout(() => {
                    this.query = value;

                    if (this.query.length >= 3 || this.query.length === 0) {
                        this.fetchCategories();
                    }
                }, 500);
            }
        },
        userCanCreate() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('category:create');
        },
        userCanUpdate() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('category:update');
        },
        userCanDelete() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('category:delete');
        },
        userCanRestore() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('category:restore');
        },
        canCreateCategory() {
            return this.v$.newCategory.name.$errors.length !== 0
                || this.v$.newCategory.slug.$errors.length !== 0
                || this.v$.newCategory.icon.$errors.length !== 0
                || this.v$.newCategory.parent_category.$errors.length !== 0
                || this.v$.newCategory.listType.$errors.length !== 0
                || this.newCategory.name.length === 0
                || this.newCategory.slug.length === 0
                || this.newCategory.parent_category === null
                || this.newCategory.icon.length === 0;
        },
        canUpdateCategory() {
            return this.v$.updateCategory.name.$errors.length !== 0
                || this.v$.updateCategory.slug.$errors.length !== 0
                || this.v$.updateCategory.icon.$errors.length !== 0
                || this.v$.updateCategory.parent_category.$errors.length !== 0
                || this.v$.updateCategory.listType.$errors.length !== 0
                || this.updateCategory.name.length === 0
                || this.updateCategory.slug.length === 0
                || this.updateCategory.parent_category === null
                || this.updateCategory.icon.length === 0;
        },
    },
    methods: {
        formatDate(value) {
            return value.toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        rowClass(data) {
            return data.deleted_at !== null ? 'deleted-row' : null;
        },
        createSlug(value) {
            let slug = value.toLowerCase().replaceAll(/[^a-z0-9\s-_áéíóúñ]/g, '');
            slug = slug.replaceAll(/á/g, 'a');
            slug = slug.replaceAll(/é/g, 'e');
            slug = slug.replaceAll(/í/g, 'i');
            slug = slug.replaceAll(/ó/g, 'o');
            slug = slug.replaceAll(/ú/g, 'u');
            slug = slug.replaceAll(/ñ/g, 'n');
            slug = slug.replaceAll(/[\s_]/g, '-');

            return slug;
        },
        newCategoryNameChange(ev) {
            this.newCategory.slug = this.createSlug(ev.srcElement.value);

            if (this.newCategory.parent_category) {
                this.parentChange(false);
            } else {
                this.v$.newCategory.slug.$touch();
            }
        },
        updateCategoryNameChange(ev) {
            this.updateCategory.slug = this.createSlug(ev.srcElement.value);

            if (this.updateCategory.parent_category) {
                this.parentChange(true);
            } else {
                this.v$.updateCategory.slug.$touch();
            }
        },
        confirmDeleteSelected() {
            this.deleteCategoriesDialog = true;
        },
        confirmDeleteCategory(category) {
            this.category = category;
            this.deleteSubcategories = false;
            this.deleteCategoryDialog = true;
        },
        confirmRestoreCategory(category) {
            this.category = category;
            this.restoreCategoryDialog = true;
        },
        fetchCategories(createAnother) {
            if (this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('category:list')) {
                this.loading = true;
                this.categoryService.fetchCategoriesTree(this.$appState.estate.id, this.withTrashed, this.query).then(response => {
                    let ac = [{
                        key: response.data.categories[0].id,
                        label: response.data.categories[0].nameWithSlug,
                        data: {
                            id: response.data.categories[0].id,
                            slug: response.data.categories[0].slug,
                            name: response.data.categories[0].name,
                            short_desc: response.data.categories[0].short_desc,
                            extra_data: response.data.categories[0].extra_data,
                            created_at: new Date(response.data.categories[0].created_at)
                        },
                        children: []
                    }];
                    this.buildTree(ac[0].children, response.data.categories[0]);
                    this.allCategories = ac;
                    let cs = [];
                    this.buildTree2(cs, response.data.categories[0]);
                    this.categories = cs;

                    this.categoryService.fetchCategories(this.$appState.estate.id, false, '').then(response => {
                        this.categoryList = response.data.categories;
                        this.loading = false;
    
                        if (createAnother) {
                            this.showCreateCategoryDialog();
                        }
                    });
                }).catch(error => {
                    console.error(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.loading = false;
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'C-01: No se ha podido obtener las categorías', life: 3000});
                });
            } else {
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'C-00: Acceso no autorizado', life: 3000});
            }
        },
        buildTree(a, p) {
            p.children.forEach(c => {
                let ch = {
                    key: c.id,
                    label: c.nameWithSlug,
                    data: {
                        id: c.id,
                        slug: c.slug,
                        name: c.name,
                        short_desc: c.short_desc,
                        extra_data: c.extra_data,
                        created_at: new Date(c.created_at)
                    },
                    children: []
                };

                if (c.children && c.children.length > 0) {
                    this.buildTree(ch.children, c);
                }

                a.push(ch);
            });
        },
        buildTree2(a, p) {
            p.children.forEach(c => {
                let ch = {
                    key: c.id,
                    data: {
                        id: c.id,
                        slug: c.slug,
                        name: c.name,
                        short_desc: c.short_desc,
                        extra_data: c.extra_data,
                        created_at: new Date(c.created_at)
                    },
                    children: []
                };

                if (c.children && c.children.length > 0) {
                    this.buildTree(ch.children, c);
                }

                a.push(ch);
            });
        },
        pageChanged(event) {
            this.page = event.page;
            this.fetchCategories();
        },
        deleteCategory() {
            this.deleteCategoryDialog = false;
            this.loading = true;
            this.categoryService.deleteCategory(this.category.id, this.deleteSubcategories).then(() => {
                this.category = null;
                this.fetchCategories();
            }).catch(error => {
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido eliminar la categoría', life: 3000});
            });
        },
        restoreCategory() {
            this.restoreCategoryDialog = false;
            this.loading = true;
            this.categoryService.restoreCategory(this.category.key).then(() => {
                this.category = null;
                this.fetchCategories();
            }).catch(error => {
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido restaurar la categoría', life: 3000});
            });
        },
        showCreateCategoryDialog() {
            this.newCategory = {
                parent_category: null,
                name: '',
                short_desc: '',
                slug: '',
                iconType: iconTypes[0],
                icon: 'mdi:folder-text',
                iconColor: '6366F1',
                listType: listTypes[0]
            };
            this.selectedFile = null;
            this.createCategoryDialog = true;
        },
        createCategory(createAnother) {
            if (!this.working) {
                this.working = true;
                this.registerError = null;

                if (this.newCategory.iconType.value === 'icon') {
                    this.categoryService.createCategory(
                        this.$appState.estate.id,
                        this.newCategory.name,
                        this.newCategory.short_desc,
                        this.newCategory.slug,
                        this.newCategory.iconType.value,
                        this.newCategory.icon,
                        this.newCategory.iconColor,
                        Object.keys(this.newCategory.parent_category)[0],
                        this.newCategory.listType.value
                    ).then(response => {
                        if (response.data.category) {
                            this.working = false;
                            this.createCategoryDialog = false;
                            this.selectedFile = null;
                            this.fetchCategories(createAnother);
                        } else {
                            this.registerError = 'ERROR C-03: Ha ocurrido un error al crear la categoría';
                        }
                    }).catch(error => {
                        console.log(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {
                                name: this.newCategory.name,
                                short_desc: this.newCategory.short_desc,
                                slug: this.newCategory.slug,
                                icon_type: this.newCategory.iconType.value,
                                icon: this.newCategory.icon,
                                icon_color: this.newCategory.iconColor,
                                parent_category: Object.keys(this.newCategory.parent_category)[0],
                                list_type: this.newCategory.listType.value
                            }),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.registerError = 'ERROR C-02: Ha ocurrido un error al crear la categoría';
                    });
                } else {
                    let fd = new FormData();
                    fd.append('estate_id', this.$appState.estate.id);
                    fd.append('name', this.newCategory.name);

                    if (this.newCategory.short_desc)
                        fd.append('short_desc', this.newCategory.short_desc);

                    fd.append('slug', this.newCategory.slug);
                    fd.append('icon_type', this.newCategory.iconType.value);
                    fd.append('icon_image', this.selectedFile);
                    fd.append('parent_id', Object.keys(this.newCategory.parent_category)[0]);
                    fd.append('list_type', this.newCategory.listType.value);

                    this.categoryService.createCategoryWithFile(fd)
                        .then(response => {
                            if (response.data.category) {
                                this.working = false;
                                this.createCategoryDialog = false;
                                this.selectedFile = null;
                                this.fetchCategories(createAnother);
                            } else {
                                this.registerError = 'ERROR C-03: Ha ocurrido un error al crear la categoría';
                            }
                        }).catch(error => {
                            console.log(error);
                            this.errorReportService.sendReport(
                                this.$appState.visitorId,
                                window.navigator.userAgent,
                                this.$appState.estate.id,
                                this.errorReportService.getRequestData(error.request, {
                                    name: this.newCategory.name,
                                    short_desc: this.newCategory.short_desc,
                                    slug: this.newCategory.slug,
                                    icon_type: this.newCategory.iconType.value,
                                    icon: this.newCategory.icon,
                                    icon_color: this.newCategory.iconColor,
                                    parent_category: Object.keys(this.newCategory.parent_category)[0],
                                    list_type: this.newCategory.listType.value
                                }),
                                this.errorReportService.getErrorData(error.response),
                            );
                            this.working = false;
                            this.registerError = 'ERROR C-02: Ha ocurrido un error al crear la categoría';
                        });
                }
            }
        },
        showUpdateCategoryDialog(categoryId) {
            this.loading = true;

            this.categoryService.fetchCategory(categoryId).then(response => {
                this.category = response.data.category;
                this.updateCategory.parent_category = {};
                this.updateCategory.parent_category[this.category.parent_category.id] = true;
                this.updateCategory.name = this.category.name;
                this.updateCategory.short_desc = this.category.short_desc;
                this.updateCategory.slug = this.category.slug;
                this.updateCategory.iconType = iconTypes.find(it => it.value === this.category.extra_data.icon_type) ?? iconTypes[0];
                this.updateCategory.icon = this.category.extra_data.icon;
                this.updateCategory.iconColor = this.category.extra_data.icon_color ?? '6366F1';
                this.updateCategory.listType = listTypes.find(lt => lt.value === this.category.extra_data.listType) ?? listTypes[0];
                this.loading = false;
                this.selectFile = null;
                this.updateCategoryDialog = true;
            }).catch(error => {
                console.error(error);
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'C-04: No se ha podido obtener los datos del categoría', life: 3000});
            });
        },
        saveCategory() {
            if (!this.working) {
                this.working = true;
                this.updateError = null;

                if (this.updateCategory.iconType.value === 'icon' || (this.updateCategory.iconType.value === 'image' && !this.selectedFile)) {
                    this.categoryService.updateCategory(
                        this.category.id,
                        this.updateCategory.name,
                        this.updateCategory.short_desc,
                        this.updateCategory.slug,
                        this.updateCategory.iconType.value,
                        this.updateCategory.icon,
                        this.updateCategory.iconColor,
                        Object.keys(this.updateCategory.parent_category)[0],
                        this.updateCategory.listType.value,
                    ).then(response => {
                        if (response.data.category) {
                            this.working = false;
                            this.updateCategoryDialog = false;
                            this.category = null;
                            this.selectedFile = null;
                            this.fetchCategories();
                        } else {
                            this.updateError = 'ERROR D-07: Ha ocurrido un error al actualizar la categoría';
                        }
                    }).catch(error => {
                        console.log(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {
                                name: this.updateCategory.name,
                                short_desc: this.updateCategory.short_desc,
                                slug: this.updateCategory.slug,
                                icon_type: this.updateCategory.iconType.value,
                                icon: this.updateCategory.icon,
                                icon_color: this.updateCategory.iconColor,
                                parent_category: Object.keys(this.updateCategory.parent_category)[0],
                                list_type: this.updateCategory.listType.value
                            }),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.updateError = 'ERROR D-06: Ha ocurrido un error al actualizar la categoría';
                    });
                } else {
                    let fd = new FormData();
                    fd.append('_method', 'put');
                    fd.append('name', this.updateCategory.name);

                    if (this.updateCategory.short_desc)
                        fd.append('short_desc', this.updateCategory.short_desc);

                    fd.append('slug', this.updateCategory.slug);
                    fd.append('icon_type', this.updateCategory.iconType.value);
                    fd.append('icon_image', this.selectedFile ?? null);
                    fd.append('parent_id', Object.keys(this.updateCategory.parent_category)[0]);
                    fd.append('list_type', this.updateCategory.listType.value);

                    this.categoryService.updateCategoryWithFile(this.category.id, fd)
                        .then(response => {
                            if (response.data.category) {
                                this.working = false;
                                this.updateCategoryDialog = false;
                                this.category = null;
                                this.selectedFile = null;
                                this.fetchCategories();
                            } else {
                                this.updateError = 'ERROR D-07: Ha ocurrido un error al actualizar la categoría';
                            }
                        }).catch(error => {
                            console.log(error);
                            this.errorReportService.sendReport(
                                this.$appState.visitorId,
                                window.navigator.userAgent,
                                this.$appState.estate.id,
                                this.errorReportService.getRequestData(error.request, {
                                    name: this.updateCategory.name,
                                    short_desc: this.updateCategory.short_desc,
                                    slug: this.updateCategory.slug,
                                    icon_type: this.updateCategory.iconType.value,
                                    icon: this.updateCategory.icon,
                                    icon_color: this.updateCategory.iconColor,
                                    parent_category: Object.keys(this.updateCategory.parent_category)[0],
                                    list_type: this.updateCategory.listType.value
                                }),
                                this.errorReportService.getErrorData(error.response),
                            );
                            this.working = false;
                            this.updateError = 'ERROR D-06: Ha ocurrido un error al actualizar la categoría';
                        });
                }
            }
        },
        selectIcon(isUpdate) {
            this.$emit('selectIcon', (icon) => {
                if (!isUpdate) {
                    this.newCategory.icon = icon;
                } else {
                    this.updateCategory.icon = icon;
                }
            });
        },
        parentChange(isUpdate) {
            if (!isUpdate) {
                let pc = this.categoryList.find(c => c.id == Object.keys(this.newCategory.parent_category)[0]);
                this.newCategory.slug = this.buildSlug(pc, this.newCategory.slug);
                this.v$.newCategory.slug.$touch();
            } else {
                let pc = this.categoryList.find(c => c.id == Object.keys(this.updateCategory.parent_category)[0]);
                this.updateCategory.slug = this.buildSlug(pc, this.updateCategory.slug);
                this.v$.updateCategory.slug.$touch();
            }
        },
        buildSlug(c, slug) {
            if (c.parent_category) {
                this.buildSlug(c.parent_category, slug);
            }

            return c.slug + '-' + slug;
        },
        fileChanged(event) {
            this.selectedFile = event.files[0];
        },
        getIconImageUrl(id) {
            const token = window.sessionStorage.getItem('token');
            return `${ApiService.getHost()}/categories/${id}/icon-image?_token=${token}`;
        },
        setDefaultIcon() {
            if (this.updateCategory.iconType.value === 'icon') {
                this.updateCategory.icon = 'mdi:folder-text';
                this.v$.updateCategory.icon.$touch();
            }
        },
    }
}
</script>

<style scoped lang="scss">
    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 960px) {
            align-items: start;
        }
    }

    ::v-deep(.p-treetable .p-treetable-tbody > tr.deleted-row) {
        background-color: var(--red-50);
    }

	.customer-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-true {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-false {
			background: #FEEDAF;
			color: #8A5340;
		}
	}

    @media screen and (max-width: 960px) {
        ::v-deep(.p-toolbar) {
            flex-wrap: wrap;
            
            .p-button {
                margin-bottom: 0.25rem;
            }
        }
    }
</style>
